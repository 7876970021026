import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
const _c0 = ["priva-link", ""];
const _c1 = ["*"];
var PrivaLinkThemeClass;
(function (PrivaLinkThemeClass) {
  PrivaLinkThemeClass["onwhite"] = "link--theme-on-white";
  PrivaLinkThemeClass["onlightgray"] = "link--theme-on-light-gray";
  PrivaLinkThemeClass["onlightblue"] = "link--theme-on-light-blue";
  PrivaLinkThemeClass["ondarkblue"] = "link--theme-on-dark-blue";
})(PrivaLinkThemeClass || (PrivaLinkThemeClass = {}));
class PrivaLinkComponent {
  constructor() {
    this.theme = 'onwhite';
  }
  get hostClasses() {
    return ['link', PrivaLinkThemeClass[this.theme?.toLowerCase()], this.class || ''].join(' ');
  }
  static {
    this.ɵfac = function PrivaLinkComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLinkComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaLinkComponent,
      selectors: [["", "priva-link", ""]],
      hostVars: 2,
      hostBindings: function PrivaLinkComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        class: "class",
        theme: "theme"
      },
      standalone: false,
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function PrivaLinkComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLinkComponent, [{
    type: Component,
    args: [{
      selector: '[priva-link]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content />\n"
    }]
  }], null, {
    class: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class PrivaLinkModule {
  static {
    this.ɵfac = function PrivaLinkModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLinkModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaLinkModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLinkModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaLinkComponent],
      imports: [CommonModule],
      exports: [PrivaLinkComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaLinkComponent, PrivaLinkModule };
