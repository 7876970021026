import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { input, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { PrivaLocalizationModule } from '@priva/localization';
import * as i1 from '@ngx-translate/core';
const _c0 = [[["", "data-label", ""]], [["", "data-value", ""]]];
const _c1 = ["[data-label]", "[data-value]"];
const _c2 = [[["priva-description-list-item"]]];
const _c3 = ["priva-description-list-item"];
const _c4 = (a0, a1, a2, a3, a4) => [a0, a1, a2, a3, a4];
const _c5 = a0 => ({
  item: a0
});
function PrivaDescriptionListComponent_For_2_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(1, 1, item_r1.value));
  }
}
function PrivaDescriptionListComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "priva-description-list-item")(1, "span", 2);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "span", 3);
    i0.ɵɵelementContainer(5, 4);
    i0.ɵɵtemplate(6, PrivaDescriptionListComponent_For_2_ng_template_6_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const itemValueTmpDefault_r2 = i0.ɵɵreference(7);
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 3, item_r1.label));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.itemValueTmp() || itemValueTmpDefault_r2)("ngTemplateOutletContext", i0.ɵɵpureFunction1(5, _c5, item_r1));
  }
}
var PrivaDescriptionListLayoutClass;
(function (PrivaDescriptionListLayoutClass) {
  PrivaDescriptionListLayoutClass["stacked"] = "description-list-stacked";
  PrivaDescriptionListLayoutClass["inline"] = "description-list-inline";
})(PrivaDescriptionListLayoutClass || (PrivaDescriptionListLayoutClass = {}));
var PrivaDescriptionListDirectionClass;
(function (PrivaDescriptionListDirectionClass) {
  PrivaDescriptionListDirectionClass["vertical"] = "description-list-vertical";
  PrivaDescriptionListDirectionClass["horizontal"] = "description-list-horizontal";
  PrivaDescriptionListDirectionClass["grid"] = "description-list-grid";
})(PrivaDescriptionListDirectionClass || (PrivaDescriptionListDirectionClass = {}));
var PrivaDescriptionListDensityClass;
(function (PrivaDescriptionListDensityClass) {
  PrivaDescriptionListDensityClass["sm"] = "description-list-density-sm";
  PrivaDescriptionListDensityClass["md"] = "description-list-density-md";
})(PrivaDescriptionListDensityClass || (PrivaDescriptionListDensityClass = {}));
var PrivaDescriptionListInlineRatioClass;
(function (PrivaDescriptionListInlineRatioClass) {
  PrivaDescriptionListInlineRatioClass["1:1"] = "";
  PrivaDescriptionListInlineRatioClass["1:2"] = "description-list-inline-1-2";
  PrivaDescriptionListInlineRatioClass["2:1"] = "description-list-inline-2-1";
})(PrivaDescriptionListInlineRatioClass || (PrivaDescriptionListInlineRatioClass = {}));
var PrivaDescriptionListThemeClass;
(function (PrivaDescriptionListThemeClass) {
  PrivaDescriptionListThemeClass["onwhite"] = "description-list-theme-on-white";
  PrivaDescriptionListThemeClass["onlightgray"] = "description-list-theme-on-light-gray";
  PrivaDescriptionListThemeClass["onlightblue"] = "description-list-theme-on-light-blue";
  PrivaDescriptionListThemeClass["ondarkblue"] = "description-list-theme-on-dark-blue";
})(PrivaDescriptionListThemeClass || (PrivaDescriptionListThemeClass = {}));
class PrivaDescriptionListItemComponent {
  constructor() {
    this.label = input();
    this.value = input();
  }
  static {
    this.ɵfac = function PrivaDescriptionListItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDescriptionListItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaDescriptionListItemComponent,
      selectors: [["priva-description-list-item"]],
      inputs: {
        label: [1, "label"],
        value: [1, "value"]
      },
      ngContentSelectors: _c1,
      decls: 9,
      vars: 6,
      consts: [[1, "description-list-item"], [1, "description-list-item-label"], [1, "description-list-item-value"]],
      template: function PrivaDescriptionListItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0)(1, "span", 1);
          i0.ɵɵtext(2);
          i0.ɵɵpipe(3, "translate");
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "span", 2);
          i0.ɵɵtext(6);
          i0.ɵɵpipe(7, "translate");
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, ctx.label()));
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 4, ctx.value()));
        }
      },
      dependencies: [PrivaLocalizationModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDescriptionListItemComponent, [{
    type: Component,
    args: [{
      selector: 'priva-description-list-item',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [PrivaLocalizationModule],
      template: "<div class=\"description-list-item\">\n    <span class=\"description-list-item-label\"\n        >{{ label() | translate }}<ng-content select=\"[data-label]\"\n    /></span>\n    <span class=\"description-list-item-value\"\n        >{{ value() | translate }}<ng-content select=\"[data-value]\"\n    /></span>\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], null, null);
})();
class PrivaDescriptionListComponent {
  constructor() {
    this.theme = input('onwhite');
    this.layout = input('stacked');
    this.inlineRatio = input('1:1');
    this.direction = input('vertical');
    this.density = input('md');
    this.minWidth = input();
    this.spacing = input();
    /* @deprecated in favor of using projected PrivaDescriptionListItemComponent */
    this.items = input();
    /* @deprecated in favor of using projected PrivaDescriptionListItemComponent */
    this.itemValueTmp = input();
    this.DirectionClass = PrivaDescriptionListDirectionClass;
    this.InlineRatioClass = PrivaDescriptionListInlineRatioClass;
    this.ThemeClass = PrivaDescriptionListThemeClass;
    this.LayoutClass = PrivaDescriptionListLayoutClass;
    this.DensityClass = PrivaDescriptionListDensityClass;
  }
  static {
    this.ɵfac = function PrivaDescriptionListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDescriptionListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaDescriptionListComponent,
      selectors: [["priva-description-list"]],
      hostVars: 4,
      hostBindings: function PrivaDescriptionListComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("--description-list__item_min-width", ctx.minWidth() + "px")("--description-list__item_spacing", ctx.spacing() + "px");
        }
      },
      inputs: {
        theme: [1, "theme"],
        layout: [1, "layout"],
        inlineRatio: [1, "inlineRatio"],
        direction: [1, "direction"],
        density: [1, "density"],
        minWidth: [1, "minWidth"],
        spacing: [1, "spacing"],
        items: [1, "items"],
        itemValueTmp: [1, "itemValueTmp"]
      },
      ngContentSelectors: _c3,
      decls: 4,
      vars: 7,
      consts: [["itemValueTmpDefault", ""], [1, "description-list", 3, "ngClass"], ["data-label", ""], ["data-value", ""], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function PrivaDescriptionListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c2);
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵrepeaterCreate(1, PrivaDescriptionListComponent_For_2_Template, 8, 7, "priva-description-list-item", null, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(1, _c4, ((tmp_0_0 = ctx.layout()) == null ? null : tmp_0_0.toLowerCase()) === "inline" ? ctx.LayoutClass["inline"] + " " + ctx.InlineRatioClass[ctx.inlineRatio()] : "", ctx.LayoutClass[(tmp_0_0 = ctx.layout()) == null ? null : tmp_0_0.toLowerCase()], ctx.ThemeClass[(tmp_0_0 = ctx.theme()) == null ? null : tmp_0_0.toLowerCase()], ctx.DirectionClass[(tmp_0_0 = ctx.direction()) == null ? null : tmp_0_0.toLowerCase()], ctx.DensityClass[(tmp_0_0 = ctx.density()) == null ? null : tmp_0_0.toLowerCase()]));
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.items());
        }
      },
      dependencies: [CommonModule, i1$1.NgClass, i1$1.NgTemplateOutlet, PrivaDescriptionListItemComponent, PrivaLocalizationModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDescriptionListComponent, [{
    type: Component,
    args: [{
      selector: 'priva-description-list',
      standalone: true,
      imports: [CommonModule, PrivaDescriptionListItemComponent, PrivaLocalizationModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[style.--description-list__item_min-width]': 'minWidth() + "px"',
        '[style.--description-list__item_spacing]': 'spacing() + "px"'
      },
      template: "<div\n    class=\"description-list\"\n    [ngClass]=\"[\n        layout()?.toLowerCase() === 'inline'\n            ? LayoutClass['inline'] + ' ' + InlineRatioClass[inlineRatio()]\n            : '',\n        LayoutClass[layout()?.toLowerCase()],\n        ThemeClass[theme()?.toLowerCase()],\n        DirectionClass[direction()?.toLowerCase()],\n        DensityClass[density()?.toLowerCase()],\n    ]\"\n>\n    @for (item of items(); track item) {\n        <priva-description-list-item>\n            <span data-label>{{ item.label | translate }}</span>\n            <span data-value\n                ><ng-container\n                    [ngTemplateOutlet]=\"itemValueTmp() || itemValueTmpDefault\"\n                    [ngTemplateOutletContext]=\"{ item: item }\"\n                /><ng-template #itemValueTmpDefault>{{ item.value | translate }}</ng-template></span\n            >\n        </priva-description-list-item>\n    }\n\n    <ng-content select=\"priva-description-list-item\" />\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], null, null);
})();

/** @deprecated import standalone `PrivaDescriptionListComponent` instead */
class PrivaDescriptionListModule {
  static {
    this.ɵfac = function PrivaDescriptionListModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDescriptionListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaDescriptionListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [PrivaDescriptionListComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDescriptionListModule, [{
    type: NgModule,
    args: [{
      imports: [PrivaDescriptionListComponent],
      exports: [PrivaDescriptionListComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaDescriptionListComponent, PrivaDescriptionListModule };
